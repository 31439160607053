<!-- 
	Priklad vstupnich hodnot
	<span class="block">We create <span class="highlight">projects, educational</span></span>
	<span class="block"><span class="highlight">events and programs</span></span>
	<span class="block">in support of business. We introduce <span class="highlight">key topics</span></span>
	<span class="block">for our future, and we want to cultivate Czech society.</span>
 -->

<script setup>
import { vElementVisibility } from "@vueuse/components";

const showAnimation = ref(false);

const props = defineProps({
  title: {
    type: String,
  },
});

const visibilityChanged = (isVisible) => {
  if (!showAnimation.value) {
    showAnimation.value = isVisible;
  }
};
</script>

<template>
  <h2
    v-element-visibility="visibilityChanged"
    class="animate-perex h1 pointer-events-none h1-mobile"
    :class="{ animating: showAnimation }"
    v-html="title"
  />
</template>

<style lang="scss" scoped>
.animate-perex:deep(.highlight) {
  display: inline;
  padding: 0 8px;
  background-repeat: no-repeat;
  transition: 0.75s ease-in-out 0.5s;
  background-size: 0% 100%;
  background-image: linear-gradient(#1a1a1a, #1a1a1a);
}
.animate-perex.animating:deep(.highlight) {
  background-size: 100% 100%;
  color: #fff;
}
</style>
